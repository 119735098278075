<template>
  <div>
    <div style="background-color: #363F46;position: relative;">
      <img src="../../assets/smartHomeImg/banner(1).png" alt="" style="width:100%;">
      <div class="pictureCls">
      <div>
        <div class="title_Cls">
          <div class="content">金指云</div>
          <div>
            <img src="../../assets/smartHomeImg/title.png" alt="">
          </div>
        </div>
        <div style=" display: flex;align-items: center;">
          <div class="sort">轻量</div>
          <div class="segmentation"></div>
          <div class="sort">执行</div>
          <div class="segmentation"></div>
          <div class="sort">追溯</div>
          <div class="segmentation"></div>
          <div class="sort">透明</div>
          <div class="segmentation"></div>
          <div class="sort">决策</div>
        </div>
        <div class="theme">
          以数字化创新技术服务社会、改变世界，以智能力量推动粉末成型应用<br>
          行业数字化转型，助力构建数字化的智能世界
        </div>
      </div>
      <div class="FreeApplication" style="cursor:pointer;" @click="pageHref">
        免费申请试用
      </div>
      <div class="helpCls">金指云是一款更懂粉末成型应用产业精益生产的数字化产品，为企业数字化转型助力</div>
    </div>
    </div>



    <!-- 右侧悬浮界面区域 -->
    <div class="suspensionRight">
      <div class="twoDimensional">
        <img src="../../assets/img/erweima.png" alt="">
        <div class="tips">扫一扫获取专属顾问</div>
      </div>
      <div class="tryCls">
        申请使用
      </div>
      <div class="connectionCls">
        联系客服
      </div>
    </div>

    <div class="upperCLs">
      <div class="suspension">
        <div>
          <div style="display: flex;align-items: center">
            <div class="separation">
            </div>
            <div class="titles">提升企业精益生产</div>
          </div>
          <div class="content">
            帮助企业优化生产环节，减少企业资源<br>
            浪费，持续精益。
          </div>
        </div>
        <div class="solidLine"></div>
        <div>
          <div style="display: flex;align-items: center">
            <div class="separation">
            </div>
            <div class="titles">打造透明化工厂</div>
          </div>
          <div class="content">
            通过大屏看板、PC、移动端等方式集中<br>
            呈现，实时掌握生产的真实情况。
          </div>
        </div>
        <div class="solidLine"></div>
        <div>
          <div style="display: flex;align-items: center">
            <div class="separation">
            </div>
            <div class="titles">轻量化快速部署实施</div>
          </div>
          <div class="content">
            深刻理解行业需求与痛点，量身打造实<br>
            施计划，分阶段为企业快速部署实施。
          </div>
        </div>

      </div>
    </div>


    <div class="introduceCls">
      <div class="juli">
        <div class="news_banner" style="">
          <p class="news_banner_title">金指云2024版全新发布</p>
          <p class="news_banner_button"><span class="news_banner_button_text">了解详情<span style="padding-left: 10px;">→</span></span></p>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;">
          <div>
            <img src="../../assets/smartHomeImg/应用.png" alt="" class="news_banner_img">
            <span class="goldCls">金指云</span>
          </div>
          <div class="contentCls">
            金指云是一款更懂粉末成型应用产业精益生产的数字化产品，配置生产管理、设备管理、质量检测、仓储管理、大屏看板、追溯中心等功能模块，帮助企业打造以精益生产为中心，持续优化生产效率，实现管理透明化，做到有迹可循，持续精益。
          </div>
        </div>
        <div class="dituCls">
          <div style="display: flex;">
            <div class="entity">
              <div class="title_biaoti">降成本</div>
              <div class="title_center">生产环节降成本，决策环节降成本</div>
              <div class="theme">
                <div class="column">优化工序</div>
                <div class="column">优化运维</div>
                <div class="column">提高利用率</div>
                <div class="column">优化生产</div>
              </div>
            </div>
            <div class="entity" style="background-color: rgb(32 32 32 / 86%);">
              <div class="title_biaoti">落地快</div>
              <div class="title_center">云端SaaS服务部署，配置快捷，操作简单。</div>
              <div class="theme">
                <div class="column" style="background-color: #1C1C1C;">轻量云MES</div>
                <div class="column" style="background-color: #1C1C1C;">专注新材料</div>
                <div class="column" style="background-color: #1C1C1C;">专业团队研发</div>
              </div>
            </div>
            <div class="entity">
              <div class="title_biaoti">易扩展</div>
              <div class="title_center">模块化组件，选择即用。</div>
              <div class="theme">
                <div class="column">公开的API文档</div>
                <div class="column">拖拽式数据表格展示</div>
              </div>
            </div>
          </div>

          <div class="item_Cls">
            <div class="card">
              <div class="title">质量追溯</div>
              <div class="center">
                实现现车间生产可视监控，彻底帮助<br>
                企业改善生产现场管理的暗箱操作。
              </div>
            </div>
            <div class="card">
              <div class="title">工单管理</div>
              <div class="center">
                实现现车间生产可视监控，彻底帮助<br>
                企业改善生产现场管理的暗箱操作。
              </div>
            </div>
            <div class="card">
              <div class="title">排产管理</div>
              <div class="center">
                实现现车间生产可视监控，彻底帮助<br>
                企业改善生产现场管理的暗箱操作。
              </div>
            </div>
            <div class="card">
              <div class="title">智能仓储</div>
              <div class="center">
                实现现车间生产可视监控，彻底帮助<br>
                企业改善生产现场管理的暗箱操作。
              </div>
            </div>
            <div class="card">
              <div class="title">透明生产</div>
              <div class="center">
                丰富的报表、看板使生产透明化，<br>
                帮助快速决策；
              </div>
            </div>
            <div class="card">
              <div class="title">过程监控</div>
              <div class="center">
                电子归档，明确生产节点，<br>
                跟踪计划完成情况；
              </div>
            </div>
            <div class="card">
              <div class="title">预防性保养计划</div>
              <div class="center">
                大幅度减少安排计划等行政工作，维护<br>
                保养计划自动生成；
              </div>
            </div>

            <div class="card">
              <div class="title">自动诊断</div>
              <div class="center">
                了解设备运行状，自定义分析诊断模<br>
                型，智能报警
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="consultCls">
      <div class="fuuwCls">
        <div class="title_fuw">全方位企业服务</div>
        <div class="content_fuw">以数字化创新技术服务社会、改变<br>
          世界，以智能力量推动整个行业数<br>
          字化转型，助力构建万物互联的智能世界
        </div>
        <div @click="pageHref" class="bot_fuw">
          <div class="bot_miaos">免费咨询</div>
        </div>
      </div>

      <div>
        <div class="middle">
          <div class="left_top">
            <img src="../../assets/smartHomeImg/zhaunx.png" alt="" class="middle_img">
            <div class="middle_title">数字化转型咨询</div>
            <div class="middle_content">实施数字化转型策略和方案，利用信息技术来改变业<br>
              务流程、增强竞争力和创造价值。</div>
          </div>
        </div>
        <div class="middle topMargin">
          <div class="left_top">
            <img src="../../assets/smartHomeImg/sehngt.png" alt="" class="middle_img">
            <div class="middle_title">全流程实施服务</div>
            <div class="middle_content">需求分析、解决方案设计、软件开发、测试、部署、<br>
              维护和培训等多个环节，确保软件满足客户需求.</div>
          </div>
        </div>
      </div>
      <div style="margin-left: 25px;">
        <div class="middle">
          <div class="left_top">
            <img src="../../assets/smartHomeImg/liuc.png" alt="" class="middle_img">
            <div class="middle_title">行业生态互通</div>
            <div class="middle_content">拥有自主产品和解决方案，并与金烽烽台产品深度融<br>
              合，为客户提供全栈式解决方案的专业服务组织。</div>
          </div>
        </div>
        <div class="middle topMargin">
          <div class="left_top">
            <img src="../../assets/smartHomeImg/应用.png" alt="" class="middle_img">
            <div class="middle_title">智能决策</div>
            <div class="middle_content">通过大数据、人工智能、物联网等技术，实现决策<br>
              从数据中获取，为决策者提供决策依据。</div>
          </div>
        </div>
      </div>
    </div>

    <div class="caseCls">
      <div class="marfin_bot">
        <div class="caseCls_top">
          <div>
            <img src="../../assets/smartHomeImg/应用.png" alt="" class="img_ioc">
            <span class="goldCls">应用案例</span>
          </div>
          <div class="contentCls">
            以数字化创新技术服务社会、改变世界，以智能力量推动整个行业数字化转型，助力构建万物互联的智能世界
          </div>
        </div>
        <div>
          <div>
            <img src="../../assets/smartHomeImg/case-yifa.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-gandian.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-mingyuan.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-people.png" alt="" class="img_size">
          </div>
          <div>
            <img src="../../assets/smartHomeImg/case-yapo.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-bloomden.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-linzhi.png" alt="" class="img_size">
            <img src="../../assets/smartHomeImg/case-datan.png" alt="" class="img_size">
          </div>
        </div>
      </div>
    </div>

    <div class="form-box">
      <div class="box">
        <div class="fromCls">
          <div class="big-title">预约产品演示</div>
          <div class="form-div-box">
            <div class="form-div-input">
              <input placeholder="请填写公司名称" v-model="company">
              <input placeholder="请填写您的称呼" v-model="name">
              <input placeholder="请填写您的手机号" v-model="phone">
              <div class="form-div-submit" @click="getFromList">提交</div>
            </div>
            <div class="erweima-box">
              <img src="../../assets/img/erweima.png" alt="" class="imgCls">
              <div class="erweima-title">微信扫码获取专属顾问</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      company: '',
      city: '',
      industry: '',
      name: '',
      phone: '',
      demand: '',
      one: '',
    }
  },
  methods: {
    getFromList() {
      if (this.company !== '' && this.name !== '' && this.phone !== '') {
        debugger
        axios.get('/Finance/Clue!saveOfficialWebsiteClues.action', {
          params: {
            company: this.company,
            name: this.name,
            telephone: this.phone
          }
        }).then(res => {
          if (res.status === 200) {
            this.$message({
              message: '提交成功!',
              type: 'success'
            });
          }
          this.company = ''
          this.city = ''
          this.industry = ''
          this.name = ''
          this.phone = ''
          this.demand = ''
        })
      } else {
        this.$message.error('请填写完公司名称,姓名,联系电话!')
      }
    },
    pageHref() {
      this.$router.push({
        path: '/intelligent/about',
        query: {
          vs: 1
        }
      })
    },
    viewMore() {
      this.$router.push('/intelligent/about')
    }
  }
}
</script>

<style lang="scss" scoped>
.pictureCls {
  // width: 360px;
  position: absolute;
  left: 361px;
  top: 200px;

  .title_Cls {
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    img {
      width: 317px;
    }
  }

  .content {
    margin-left: 5px;
    color: #FFF;
    font-weight: bold;
    font-size: 52px;
    padding-right: 20px;
  }

  .sort {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 32px;
  }

  .segmentation {
    width: 3px;
    height: 30px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #FFFFFF;
  }

  .theme {
    margin-top: 21px;
    margin-bottom: 24px;
    line-height: 1.5;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 20px;
  }
}


.suspensionRight {
  position: absolute;
  right: 17px;
  top: 199px
}

.twoDimensional {
  width: 118px;
  padding: 5px;
  border-radius: 6px;
  background: #FFF;
  text-align: center;
  margin-bottom: 2px;
  box-shadow: 0px 12px 20px 1px rgba(149, 100, 36, 0.08);

  img {
    width: 85px;
    height: 85px;
  }

  .tips {
    font-size: 8px;
    color: #000000;
  }
}


.tryCls {
  line-height: 30px;
  text-align: center;
  margin-bottom: 2px;
  font-size: 14px;
  color: #FFFFFF;
  width: 118px;
  height: 30px;
  background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
  box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.26);
  border-radius: 4px;
}

.connectionCls {
  text-align: center;
  line-height: 30px;
  color: #111111;
  font-size: 14px;
  width: 118px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.26);
  border-radius: 4px;
}

.FreeApplication {
  width: 276px;
  height: 48px;
  background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
  box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.39);
  border-radius: 4px 4px 4px 4px;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
}

.helpCls {
    font-size: 14px;
    color: #fff;
    padding-top: 10px;
}


.goldCls {
  margin-left: 9px;
  margin-right: 21px;
  font-size: 36px;
  font-weight: bold;
  color: #111111;
  font-family: Alimama ShuHeiTi, Alimama ShuHeiTi;
}


.introduceCls {
  width: 100%;
  height: 808px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  .news_banner {
      margin: 0px 20px 20px 20px;
      background-image: url('../../assets/smartHomeImg/news_banner.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 288px;
      .news_banner_title {
        padding-top: 72px;
        padding-left: 80px;
        font-family: Alimama ShuHeiTi, Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 36px;
        color: #111111;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .news_banner_button {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        padding-top: 10px;
        padding-left: 80px;
        font-size: 16px;
        color: #111111;
        text-align: left;
        font-style: normal;
        text-transform: none;

        .news_banner_button_text {
          background: #ffffff;
          border-radius: 6px;
          padding: 4px 12px;
        }
      }
  }

  .juli {
    margin-top: 46px;
  }

  .contentCls {
    font-size: 18px;
    color: #666666;
    width: 1028px;
    font-weight: bold;
  }
}


.dituCls {
  margin-top: 24px;
  width: 1236px;
  height: 453px;
  padding-left: 18px;
  background-image: url('../../assets/smartHomeImg/ditu.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .entity {
    padding-left: 28px;
    padding-top: 15px;
    width: 400px;
    height: 115px;
    background-color: #F69519;
  }

  .title_biaoti {
    color: #FFFFFF;
    font-size: 24px;
    margin-bottom: 5px;
  }

  .title_center {
    color: #FFFFFF;
    font-size: 14px;
  }

  .theme {
    margin-top: 8px;
    display: flex;
  }

  .column {
    text-align: center;
    margin-right: 7px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 10px 5px 10px;
    background: #E57200;
    border-radius: 4px 4px 4px 4px;
  }

  .item_Cls {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-left: 23px;
  }

  .card {
    width: 280px;
    height: 120px;
    margin-right: 12px;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/smartHomeImg/kapian.png');

    .title {
      margin-top: 18px;
      margin-left: 48px;
      font-weight: bold;
      color: #111111;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .center {
      line-height: 2;
      font-weight: bold;
      margin-left: 25px;
      color: #636363;
      font-size: 14px;
    }
  }
}

.upperCLs {
  height: 180px;
  background: #F3F8FF;
  width: 100%;
  position: relative;
}

.suspension {
  width: 1090px;
  height: 152px;
  background-color: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  left: 405px;
  top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .separation {
    width: 6px;
    margin-right: 6px;
    height: 23px;
    border-radius: 5px;
    background-image: linear-gradient(#FCA800, #E63F00);
  }

  .solidLine {
    // width: 1px;
    height: 82px;
    border: 1px solid #6666661f;
    margin-left: 67px;
    margin-right: 67px;
  }

  .titles {
    color: #111111;
    font-weight: bold;
    font-size: 24px;
  }

  .content {
    font-weight: bold;
    margin-top: 24px;
    color: #666666;
    font-size: 14px;
  }
}

.consultCls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 524px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/smartHomeImg/图.png');
}

.topMargin {
  margin-top: 20px
}

.left_top {
  margin-left: 37px;
  padding-top: 33px
}

.fuuwCls {
  margin-right: 18px;
  width: 350px;
  height: 380px;
  background-image: url('../../assets/smartHomeImg/fuwu.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .title_fuw {
    margin-top: 69px;
    margin-left: 36px;
    color: #000000;
    font-weight: bold;
    font-size: 36px;
  }

  .content_fuw {
    margin-top: 16px;
    font-weight: bold;
    color: #4D4D4D;
    font-size: 16px;
    margin-left: 36px;
  }

  .bot_fuw {
    cursor: pointer;
    margin-left: 36px;
    margin-top: 108px;
    text-align: center;
    line-height: 48px;
    width: 160px;
    height: 48px;
    background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
    box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.39);
    border-radius: 4px 4px 4px 4px;
  }

  .bot_miaos {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 18px;
  }
}

.middle {
  width: 400px;
  height: 180px;
  background-color: #FFFFFF;

  .middle_img {
    width: 32px;
    height: 32px;
    display: block;
  }

  .middle_title {
    margin-bottom: 10px;
    color: #111111;
    font-weight: bold;
    font-size: 24px;
  }

  .middle_content {
    line-height: 2;
    font-weight: bold;
    color: #888888;
    font-size: 14px;
  }
}

.img_ioc {
  width: 36px;
  height: 36px;
}

.caseCls {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;

  .marfin_bot {
    margin-bottom: 30px;
  }

  .caseCls_top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 66px;
  }

  .contentCls {
    font-size: 18px;
    color: #666666;
    width: 970px;
    font-weight: bold;
  }

  .img_size {
    width: 310px;
    height: 279px;
  }
}




.form-box {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: url('../../assets/img/form-bg.png');
  background-size: 100% 100%;
  height: 416px;
  width: 100%;
}

.box {
  width: 1424px;
  margin: 0 auto;
}

.imgCls {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 138px;
  height: 134px;
}

.fromCls {
  // width: 590px;
  // height: 312px;
  // background: #FFFFFF;
  // border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding-left: 150px;
  padding-top: 74px;
}

.big-title {
  // height: 36px;
  font-size: 36px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #111111;
  padding-left: 120px;
}

.form-div-box {
  display: flex;
  align-items: center;

  .form-div-input {
    display: flex;
    flex-direction: column;

    input {
      outline-color: #FFFFFF;
      color: #A5B7CF;
      font-size: 16px;
      width: 331px;
      height: 36px;
      color: #A5B7CF;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 0;
      margin-top: 15px;
      padding: 0 10px;
    }
  }

  .form-div-submit {
    cursor: pointer;
    width: 175px;
    height: 36px;
    background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
    box-shadow: 0px 4px 14px 1px rgb(206 104 0 / 39%);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    /* line-height: 0px; */
    text-align: center;
    line-height: 36px;
    margin-top: 25px;
  }

  .erweima-box {
    margin-left: 30px;
    background-color: #FFFFFF;
    padding: 10px;
    text-align: center;

    .erweima-title {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #A5B7CF;
    }
  }
}
.news_banner_img{
  width: 36px;
  height: 36px;
}
</style>
